<template>
  <section>
    <sub-head idName="mana-evo" content="管理沿革"></sub-head>
    <section class="box">
      <!-- 教师介绍部分 -->
      <DisplayCard
        v-for="({ imgName, teacherName, content }, index) in TeaIntroData.data"
        :key="imgName"
        :header="TeaIntroData.title"
        :index="index"
      >
        <tea-intro
          :imgName="imgName"
          :teacherName="teacherName"
          :type="index % 2 === 0 ? 'odd' : 'even'"
        >
          <p v-for="item in content" :key="item">{{ item }}</p>
        </tea-intro>
      </DisplayCard>
    </section>

    <!-- 历届主席团 (折叠部分) -->
    <section class="box">
      <div class="box-subhead">历届主席团</div>
      <details>
        <summary>查看历届主席团</summary>
        <div class="details-content">
          <DisplayCard
            v-for="(items, index) in StuIntroData.data.slice(0, -1)"
            :key="index"
            :header="StuIntroData.title"
            :index="index"
          >
            <stu-intro :data="items" :year="index + 1"></stu-intro>
          </DisplayCard>
        </div>
      </details>
    </section>

    <!-- 最后一届主席团 -->
    <section class="box">
      <div class="box-subhead">现任主席团</div>
      <DisplayCard
        v-for="(items, index) in [StuIntroData.data.slice(-1)[0]]"
        :key="index"
        :header="StuIntroData.title"
        :index="StuIntroData.data.length - 1"
      >
        <stu-intro :data="items" :year="StuIntroData.data.length"></stu-intro>
      </DisplayCard>
    </section>
  </section>
</template>




<script>
import SubHead from '../../../components/SubHead.vue'
import StuIntro from './ManaEvo/StuIntro.vue'
import TeaIntro from './ManaEvo/TeaIntro.vue'
import DisplayCard from '@/components/DisplayCard.vue'
import stuintroData from '@/assets/json/stuIntro.json'
import teacherIntroData from '@/assets/json/teacherIntro.json'

export default {
  components: { SubHead, TeaIntro, DisplayCard, StuIntro },
  name: 'ManaEvo',
  data() {
    return {
      StuIntroData: {
        title: '主席团',
        data: stuintroData
      },
      TeaIntroData: {
        title: '指导老师',
        data: teacherIntroData
      }
    }
  }
}
</script>

<style scoped>
.box-subhead {
  width: 100%; /* 设置为全宽度以覆盖标题 */
  text-align: left;
  background-color: #ffffff;
  color: #5fbcff;
  margin: 1rem 0rem 0.5rem 0;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.3rem; /* 略微增加字体大小以突出显示 */
}

details {
  margin: 1rem 0;
}

summary {
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

summary:hover {
  color: #5fbcff;
}

details[open] summary {
  color: #5fbcff;
}

.details-content {
  display: flex;
  flex-wrap: wrap;
}

.DisplayCard {
  flex: 1;
  margin: 0.5rem;
}

.odd {
  float: left;
}

.even {
  float: right;
}
</style>


